<template>
    <section>
      <Toast />
      <div class="w-full bg-white rounded-md mt-2 p-4">
        <div class="lg:flex">
          <div class="flex gap-2 items-center w-full lg:w-6/12">
            <i class="pi pi-list"></i>
            <p class="text-lg font-bold">Sincronizar Entregas Masivas</p>
          </div>
          <div class="w-full lg:w-6/12 flex lg:justify-end">
            <Button
              @click="router.push({name:'ut-segupharma.ventas.ov.sync-entrega-masiva.historial', params:{}})"
              size="small"
              label="Historial"
              class="w-full lg:w-max"
            />
          </div>
        </div>
        <div class="grid grid-cold-1 md:grid-cols-2 lg:grid-cols-5 gap-2 my-2">
          <div class="w-full">
            <label class="text-xs text-gray-600" for="numero_orden">Número de orden</label>
            <InputText class="w-full" id="numero_orden" @keyup.enter="agregarOv" v-model="ordenSeleccionada" @keydown="validacionOrdenes($event)" />
            <div class="flex flex-wrap gap-1 mt-2 max-h-24 overflow-y-scroll">
              <div v-for="(orden, i) in ordenesFiltradas" :key="i" class="">
                <div class="flex p-1 bg-blue-200 text-blue-600 rounded-md">
                  <p class="text-xs">{{ orden }}</p>
                  <button @click="eliminarOrden(orden)"><i class="pi pi-times text-xs"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="cliente">Cliente</label>
            <Dropdown
              id="cliente"
              panelClass="text-xs"
              class="w-full rounded-md"
              v-model="filtros.CardCode"
              :options="listadoClientes"
              optionLabel="CardName"
              optionValue="CardCode"
              :filter="true"
              :showClear="true">
            </Dropdown>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="fecha_inicio">Fecha inicio</label>
            <InputText
              id="fecha_inicio"
              type="date"
              class="w-full "
              v-model="filtros.fecha_inicio"
              :pt="{
                  root: { style: 'height: 37px' }
              }"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="fecha_fin">Fecha fin</label>
            <InputText
              id="fecha_fin"
              type="date"
              class="w-full "
              v-model="filtros.fecha_fin"
              :pt="{
                  root: { style: 'height: 37px' }
              }"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="bodega">Bodega</label>
            <AutoComplete
              panelClass="text-xs"
              id="bodega"
              class="w-full text-xs max-h-full"
              inputStyle="line-height: 1.3rem"
              inputClass="w-full text-xs"
              v-model="filtros.U_PHR_Bodega"
              :suggestions="bodegasFiltradas"
              @complete="buscarBodega($event)"
              field="name"
              :pt="{
                  root: { style: 'height: 37px' }
              }"
            />
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600">Identificación paciente</label>
            <div class="w-full flex gap-1">
              <Dropdown
                @change="filtros.U_PHR_NumDcto = ''"
                class="w-4/12 tipoId rounded-md"
                panelClass="text-xs"
                v-model="filtros.U_ACS_TpoIdentf"
                :options="tiposId"
                optionLabel="U_ACS_TpoIdentf"
                optionValue="U_ACS_TpoIdentf"
              />
              <AutoComplete
                class="w-8/12"
                inputClass="w-full"
                v-model="filtros.U_PHR_NumDcto"
                :suggestions="listadoPacientes"
                @complete="buscarPaciente"
                field="name"
              />
            </div>
          </div>
          <div class="w-full">
            <label class="text-xs text-gray-600" for="modalidad">Modalidad</label>
            <Dropdown
              panelClass="text-xs"
              class="w-full rounded-md"
              v-model="filtros.U_PHR_ModContrato"
              :options="listadoModalidades"
              optionLabel="descripcion"
              optionValue="value"
              id="modalidad"
              :showClear="true">
            </Dropdown>
          </div>
          <div class="flex items-end gap-2 col-span-3">
            <Button
              @click="listarOrdenes"
              size="small"
              class="rounded-md"
              label="Buscar"
              :pt="{
                root: { style: 'height: 37px' }
              }"
            />
            <Button
              @click="limpiarFiltros"
              size="small"
              class="rounded-md"
              label="Limpiar"
              severity="warning"
              :pt="{
                root: { style: 'height: 37px' }
              }"
            />
            <Button
              @click="entregaMasiva"
              size="small"
              class="rounded-md"
              severity="success"
              label="Sincronizar SAP"
              :pt="{
                root: { style: 'height: 37px' }
              }"
            />
          </div>
        </div>
        <div class="w-full my-6">
          <DataTable class="p-datatable-sm text-xs" v-model:selection="selectedOv" :value="ordenes.rows" responsiveLayout="scroll">
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column
              header="No. Orden"
              :pt="{
                headerContent: { style: { justifyContent: 'center' } }
              }"
            >
              <template #body="{data}">
                <div class="text-center pt-3">
                  <p>{{ data.Id }}</p>
                  <div class="flex gap-x-4 hover:text-blue-600 text-white transition ease-in-out duration-500">
                    <button @click="$router.push({ name: 'ut-segupharma.ventas.ov.entrega', params: {id: data.Id} })" class=" underline italic">Ver</button>
                    <!-- <button class="underline italic">Editar</button> -->
                  </div>
                </div>
              </template>
            </Column>
            <Column field="createdAt" header="Fecha">
              <template #body="{data}">
                  {{ dayjs(data.createdAt).format('YYYY-MM-DD HH:mm') }}
              </template>
            </Column>
            <Column field="CardName" header="Cliente"></Column>
            <Column field="Paciente" header="Paciente">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ data.U_ACS_TpoIdentf + ' - ' + data.U_PHR_NumDcto }}</span>
                  <span>{{ data.U_ACS_NmbPct }}</span>
                </div>
              </template>
            </Column>
            <Column field="U_PHR_ModContratoDesc" header="Modalidad"></Column>
            <Column field="U_PHR_BodegaNombre" header="Bodega"></Column>
            <Column field="CreadoPor" header="Creado por"></Column>
            <!-- <Column header="Estado">
              <template #body="{data}">
                <div class="p-2 rounded-md text-center" :class="data.DocStatus === 0 ? 'bg-green-300' : (data.DocStatus === 1 ? 'bg-blue-300' : 'bg-red-400')">
                  <p class="font-bold">
                    {{ data.DocStatus === 0 ? 'Abierto' : (data.DocStatus === 1 ? 'Cerrado' : 'Cancelado') }}
                  </p>
                </div>
              </template>
            </Column> -->
          </DataTable>
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(ordenes.count)"
            :rowsPerPageOptions="[5,10,20,30,100]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
          />
        </div>
      </div>
    </section>
</template>
<script setup>
  import dayjs from 'dayjs'
  import Swal from 'sweetalert2'
  import { useToast } from 'primevue/usetoast'
  import { useRouter } from 'vue-router'
  import EntregaService from '../../../services/sync-entrega.service'
  import ClientesService from '../../../services/clientes.service'
  import BodegasService from '../../../services/bodegas.service'
  import PacientesService from '../../../services/pacientes.service'
  import ModalidadContratoService from '../../../services/modalidad-contrato.service'
  import { onMounted, ref, computed } from 'vue'
    // name: 'ListadoOrdenesVenta',
      // Servicios
      const toast = useToast()
      const router = useRouter()
      const _EntregaService = ref(new EntregaService())
      const _ClientesService = ref(new ClientesService())
      const _BodegasService = ref(new BodegasService())
      const _PacientesService = ref(new PacientesService())
      const _ModalidadService = ref(new ModalidadContratoService())
      // Referencias
      const ordenes = ref([])
      const listadoClientes = ref([])
      const bodegasFiltradas = ref([])
      const listadoPacientes = ref([])
      const listadoModalidades = ref([])
      const ordenesFiltradas = ref([])
      const ordenSeleccionada = ref()
      const selectedOv = ref([])
      const tiposId = ref([])
      const limit = ref(10)
      const offset = ref(0)
      const filtros = ref({
        CardCode: null,
        fecha_inicio: dayjs().format('YYYY-MM-DD'),
        fecha_fin: dayjs().format('YYYY-MM-DD'),
        Id: null,
        U_PHR_Bodega: null,
        U_ACS_TpoIdentf: null,
        U_PHR_NumDcto: null,
        U_PHR_ModContrato: null
      })
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value,
          ...filtros.value
        }
      })
      // Metodos
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        listarOrdenes(params.value)
      }
      const listarOrdenes = () => {
        ordenesFiltradas.value.length ? filtros.value.Id = JSON.stringify(ordenesFiltradas.value) : filtros.value.Id = null
        const object = {
          ...params.value
        }
        object.U_PHR_Bodega ? object.U_PHR_Bodega = object.U_PHR_Bodega.WhsCode : object.U_PHR_Bodega = null
        object.DocStatus = 1
        _EntregaService.value.listarOrdenes(object).then(({ data }) => {
          ordenes.value = data
        })
      }
      const listarClientes = () => {
        _ClientesService.value.listarCLientes().then(({ data }) => {
          listadoClientes.value = data
        })
      }
      const listarTiposId = () => {
        _PacientesService.value.listarTiposId().then(({ data }) => {
          tiposId.value = data
        })
      }
      const buscarBodega = (event) => {
        _BodegasService.value.listarBodegas(event.query.toUpperCase()).then(({ data }) => {
          bodegasFiltradas.value = []
          for (const i of data) {
            const name = i.WhsCode + ' - ' + i.WhsName
            bodegasFiltradas.value.push({
              ...i,
              name
            })
          }
        })
      }
      const buscarPaciente = () => {

      }
      const listarModalidadesContrato = () => {
        _ModalidadService.value.listarModalidades().then(({ data }) => {
          listadoModalidades.value = data
        })
      }
      const agregarOv = () => {
        for (const i of ordenSeleccionada.value.split(' ')) {
          if (ordenesFiltradas.value && ordenesFiltradas.value.length) {
            if (!ordenesFiltradas.value.some(a => a === i)) {
              i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
            }
          } else {
            if (i !== '') {
              i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
            }
          }
        }
        ordenSeleccionada.value = ''
      }
      const validacionOrdenes = (e) => {
        if (/[^0-9\s]/.test(e.key)) {
          if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
            if (e.key === 'v' && !e.ctrlKey) {
              e.preventDefault()
            }
          } else {
            e.preventDefault()
          }
        }
      }
      const eliminarOrden = (numeroOv) => {
        const index = ordenesFiltradas.value.findIndex(a => a === numeroOv)
        ordenesFiltradas.value.splice(index, 1)
      }
      const limpiarFiltros = () => {
        filtros.value = {
          CardCode: null,
          fecha_inicio: dayjs().format('YYYY-MM-DD'),
          fecha_fin: dayjs().format('YYYY-MM-DD'),
          Id: null,
          U_PHR_Bodega: null,
          U_ACS_TpoIdentf: null,
          U_PHR_NumDcto: null,
          U_PHR_ModContrato: null
        }
        ordenesFiltradas.value = []
        ordenSeleccionada.value = ''
        listarOrdenes()
      }
      const entregaMasiva = () => {
        if (selectedOv.value.length) {
          Swal.fire({
            title: '¿Está seguro?',
            text: 'Esta acción no se puede deshacer',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, estoy seguro'
          }).then(async (result) => {
            if (result.isConfirmed) {
              var ordenesSend = selectedOv.value.map(m => m.Id)
              _EntregaService.value.crearEntrega(ordenesSend).then((res) => {
                toast.add({ severity: 'success', summary: 'Exito', detail: 'Se generó la entrega correctamente', life: 3000 })
                listarOrdenes()
              })
            }
          })
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Generar Entrega',
            text: 'Debe seleccionar al menos una orden de venta'
          })
        }
      }
      onMounted(() => {
        listarOrdenes()
        listarClientes()
        listarTiposId()
        listarModalidadesContrato()
      })

</script>
<style>
  .p-dropdown-label{
    font-size: 0.75rem !important
  }
  .tipoId .p-dropdown-trigger {
    display: none !important;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(189, 189, 189);
    border-radius: 9999px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-input-placeholder { /* Edge */
    color: gray;
  }
  ::-webkit-scrollbar {
    width: 0.4rem;
  }
</style>
